export const formatPrice = (price) => {
    if (typeof price !== 'number') {
        price = parseFloat(price);
    }
    
    if (isNaN(price)) {
        return '0.00';
    }

    if (price >= 1) {
        // Format the price with commas and retain decimal places
        const [integerPart, decimalPart] = price.toFixed(2).toString().split('.');
        const formattedInteger = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        // Combine formatted integer part with decimal part if it exists
        const formattedPrice = decimalPart ? `${formattedInteger}.${decimalPart}` : formattedInteger;

        console.log("formattedPrice: ", formattedPrice);

        return formattedPrice; // Return the formatted price
    } else {
        // For prices less than 0.001, show up to 3 significant figures
        return price.toPrecision(3);
    }
};