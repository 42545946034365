import arb from "../assets/crypto/ARB.png";
import op from "../assets/crypto/OP.png";
import avax from "../assets/crypto/avalanche.png";
import base from "../assets/crypto/base.png";
import usdcLogo from "../assets/crypto/USDC.png"; // Replace with the correct path or import statement
import wethLogo from "../assets/crypto/ETH.png"; // Replace with the correct path or import statement

export const enabledNetworks = [42161, 10, 43114, 8453];

export const usdcPaymasterMap = {
  42161: "0x20923d017aD535BA9d8cfB2E9b343E9ed2E35c0a",
  10: "0xD9bB5b5562537B1946FCed5e8da39A16fC522688",
  43114: "0x224e0779e0Ef924f0c0954fe2C886CF58E1a293e",
  8453: "0x232fCFD3DB08926B387dCF31d00AD23da705402a",
};

export const usdtPaymasterMap = {
  42161: "0x4F8959BC183cD80D80665Ba1e26DfbdbEE87F9d1",
  10: "0xd34CECDA3f1dD6d4A422af87982099091A5C6792",
  43114: "0x393D1A9815ae893D09c8059D9C19705706CbBc93",
};

export const USDC_CONTRACT_ADDRESS =
  "0x75faf114eafb1BDbe2F0316DF893fd58CE46AA4d";
// export const USDC_CONTRACT_ADDRESS = '0xA51c1fc2f0D1a1b8494Ed1FE312d7C3a78Ed91C0';
export const AAVE_POOL = "0xBfC91D59fdAA134A4ED45f7B584cAf96D7792Eff";

export const tokens = {
  USDC: {
    1: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    421614: "0x75faf114eafb1BDbe2F0316DF893fd58CE46AA4d",
    11155420: "0x5fd84259d66Cd46123540766Be93DFE6D43130D7",
    10: "0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85",
    42161: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
    1337: "0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9",
    43114: "0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e",
    8453: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
  },
  USDT: {
    43114: "0x9702230A8Ea53601f5cD2dc00fDBc13d4dF4A8c7",
    42161: "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9",
    10: "0x94b008aA00579c1307B0EF2c499aD98a8ce58e58",
  },
  WETH: {
    1: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
    421614: "0x980B62Da83eFf3D4576C647993b0c1D7faf17c73",
    11155420: "0x4200000000000000000000000000000000000006",
    10: "0x4200000000000000000000000000000000000006",
    42161: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
    8453: "0x4200000000000000000000000000000000000006",
  },
};

export const tokens2 = {
  USDC: {
    logo: usdcLogo,
    networks: {
      1: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
      421614: "0x75faf114eafb1BDbe2F0316DF893fd58CE46AA4d",
      11155420: "0x5fd84259d66Cd46123540766Be93DFE6D43130D7",
      10: "0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85",
      42161: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
      43114: "0xb97ef9ef8734c71904d8002f8b6bc66dd9c48a6e",
      8453: "0x833589fCD6eDb6E08f4c7C32D4f71b54bdA02913",
    },
  },
  WETH: {
    logo: wethLogo,
    networks: {
      1: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
      421614: "0x980B62Da83eFf3D4576C647993b0c1D7faf17c73",
      11155420: "0x4200000000000000000000000000000000000006",
      10: "0x4200000000000000000000000000000000000006",
      42161: "0x82aF49447D8a07e3bd95BD0d56f35241523fBab1",
      8453: "0x4200000000000000000000000000000000000006",
    },
  },
};
export const getAddressExplorer = (network, address) => {
  return `https://scope.sh/${network}/address/${address}`;
};

export const networkDetails = {
  // Network id to image mapping
  421614: {
    id: 421614,
    image: arb,
    logo: arb,
    name: "Arb Sepolia",
    nativeToken: "ETH",
    tokenLogo: wethLogo,
    explorer: "https://sepolia.arbiscan.io/",
  },
  11155420: {
    id: 11155420,
    image: op,
    logo: op,
    name: "OP Sepolia",
    nativeToken: "ETH",
    tokenLogo: wethLogo,
  },
  42161: {
    id: 42161,
    image: arb,
    logo: arb,
    name: "Arbitrum",
    nativeToken: "ETH",
    tokenLogo: wethLogo,
    birdeyeName: "arbitrum",
    swingName: "arbitrum",
    explorer: "https://arbiscan.io/",
  },
  10: {
    id: 10,
    image: op,
    logo: op,
    name: "Optimism",
    nativeToken: "ETH",
    tokenLogo: wethLogo,
    birdeyeName: "optimism",
    swingName: "optimism",
    explorer: "https://optimistic.etherscan.io/",
  },
  1337: {
    id: 1337,
    image: op,
    logo: op,
    name: "Local",
    nativeToken: "ETH",
    tokenLogo: wethLogo,
  },
  43114: {
    id: 43114,
    image: avax,
    logo: avax,
    name: "Avalanche C-Chain",
    nativeToken: "AVAX",
    tokenLogo: avax,
    birdeyeName: "avalanche",
    swingName: "avalanche",
    explorer: "https://snowtrace.io/",
  },
  8453: {
    id: 8453,
    image: base,
    logo: base,
    name: "Base",
    nativeToken: "ETH",
    tokenLogo: wethLogo,
    birdeyeName: "base",
    swingName: "base",
    explorer: "https://basescan.org/",
  },
};
