import React, { useState, useEffect, useMemo } from "react";
import Numpad from "../Numpad";
import { useEnclaveApi } from "../EnclaveSDK/context/EnclaveConnectProvider";
import { ethers } from "ethers";
import { add, debounce } from "lodash";
import {
  getERC20TransferCallData,
  getWalletAddressForUsername,
} from "../EnclaveSDK/EnclaveUtils/functions";
import arb from "../EnclaveSDK/assets/crypto/ARB.png";
import { convertToNetworkTokenList } from "../../utils/tokenListUtils";
import { processBalances2 } from "../../utils/functions";
import styles from "./common.module.css";
import Dropdown from "../SwapTokenDropdown";
import SwapTokenSelect from "../SwapTokenSelect";
import { networkDetailsByNameKey } from "../../utils/constants";
import { ArrowBack, ArrowDownwardOutlined } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import { BadgeCheck } from "lucide-react";
import {
  enabledNetworks,
  networkDetails,
} from "../EnclaveSDK/EnclaveUtils/constants";

const networkList = Object.values(networkDetailsByNameKey);

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function TransferPage() {
  const query = useQuery();

  const { balances, allTokensList } = useEnclaveApi();

  const [sourceNetwork, setSourceNetwork] = useState({
    id: 42161,
    name: "Arbitrum",
    logo: arb,
  });

  const pdestinationNetwork = query.get("pdestinationNetwork");
  const pdestinationAddress = query.get("pdestinationAddress");
  const [destinationNetwork, setDestinationNetwork] = useState(
    pdestinationNetwork
      ? networkDetails[parseInt(pdestinationNetwork)]
      : networkDetails[enabledNetworks[1]]
  );

  const destinationTokenList = useMemo(
    () => convertToNetworkTokenList(destinationNetwork.id, allTokensList),
    [destinationNetwork.id, allTokensList]
  );

  const [fromToken, setFromToken] = useState(null);

  useEffect(() => {
    if (!fromToken && destinationTokenList?.length > 0) {
      if (pdestinationAddress) {
        const foundToken = destinationTokenList.find(
          (token) =>
            token.address.toLowerCase() === pdestinationAddress.toLowerCase()
        );
        if (foundToken) {
          setFromToken(foundToken);
        } else {
          setFromToken(destinationTokenList[0]);
        }
      } else {
        setFromToken(destinationTokenList[0]);
      }
    }
  }, [destinationTokenList, pdestinationAddress]);

  console.log({ fromToken }, "@@@@@@@@@@@@");

  const [sourceNetworkBalances, setSourceNetworkBalances] = useState({});
  const [calldata, setCalldata] = useState("0x");
  const [sourceAmount, setSourceAmount] = useState("");
  const [loading, setLoading] = useState(false);

  const [address, setAddress] = useState("");
  const [sendToUser, setSendToUser] = useState({
    status: false,
    username: undefined,
    walletAddress: undefined,
  });
  const [addressToSend, setAddressToSend] = useState("");

  console.log("Send to user: ", sendToUser);
  console.log("Address to send: ", addressToSend);

  const validAmount = parseFloat(sourceAmount) > 0;
  const withinBalance =
    parseFloat(sourceAmount) <=
    parseFloat(sourceNetworkBalances[fromToken?.address.toLowerCase()]?.total);
  const validToAddress = ethers.isAddress(addressToSend);
  const validSend = validAmount && withinBalance && validToAddress;

  const navigate = useNavigate();

  const handleAddressChange = async (value) => {
    setAddress(value);
    if (value && value.length > 0) {
      setLoading(true);
      const res = await getWalletAddressForUsername(value);
      console.log("Result: ", res);
      if (res.value) {
        setSendToUser({
          status: true,
          username: value,
          walletAddress: res.walletAddress,
        });
        setAddressToSend(res.walletAddress);
      } else {
        setSendToUser({
          status: false,
          username: undefined,
          walletAddress: undefined,
        });
        setAddressToSend(value);
      }
    }
  };

  useEffect(() => {
    const balanceMap = processBalances2(
      balances.filter((balance) => balance.chainId === sourceNetwork.id)
    );
    console.log("BALANCE MAP: ", balanceMap);
    setSourceNetworkBalances(balanceMap);
  }, [balances, sourceNetwork]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (
          fromToken?.address === "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee"
        ) {
          setCalldata("0x");
          return;
        }
        if (sourceAmount && fromToken && addressToSend) {
          const calldata = getERC20TransferCallData(
            sourceAmount,
            fromToken.address,
            addressToSend,
            fromToken.decimals
          );
          setCalldata(calldata);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    const fetchDataDebounce = debounce(fetchData, 500);
    setLoading(true);
    fetchDataDebounce();

    return () => fetchDataDebounce.cancel();
  }, [sourceAmount, fromToken?.address, addressToSend, fromToken?.decimals]);

  const handleSubmit = (addressToSend) => {
    if (fromToken.address === "0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee") {
      const value = ethers.parseEther(sourceAmount);
      window.enclave.initiateTransaction(
        [
          {
            label: "Send " + fromToken.symbol,
            calldata: "0x",
            value: value,
            targetContractAddress: addressToSend,
            chainId: sourceNetwork.id,
            walletAddress: window.enclave.address,
          },
        ],
        `Send ${sourceAmount} ${fromToken.symbol} to ${
          addressToSend.substring(0, 6) +
          "..." +
          addressToSend.substring(addressToSend.length - 4)
        }`
      );
    } else {
      window.enclave.initiateTransaction(
        [
          {
            label: "Send " + fromToken.symbol,
            calldata: calldata,
            targetContractAddress: fromToken.address,
            chainId: sourceNetwork.id,
            walletAddress: window.enclave.address,
          },
        ],
        `Send ${sourceAmount} ${fromToken.symbol} to ${
          addressToSend.substring(0, 6) +
          "..." +
          addressToSend.substring(addressToSend.length - 4)
        }`
      );
    }
  };

  const handleNumberClick = (number) => {
    if (sourceAmount === "0" && number === "0") return;

    if (sourceAmount === "0" && number !== ".") {
      setSourceAmount(number);
      return;
    }

    setSourceAmount(sourceAmount + number);
  };

  const handleBackspaceClick = () => {
    setSourceAmount((prevAmount) => prevAmount.slice(0, -1));
  };

  const handlePresetClick = (preset) => {
    const balance =
      parseFloat(
        sourceNetworkBalances[fromToken.address.toLowerCase()]?.total
      ) || 0;

    if (preset === "MAX") {
      setSourceAmount(balance.toString());
    } else {
      // Convert preset (10, 25, 50, 100) to decimal (0.1, 0.25, 0.5, 1.0)
      const percentage = parseInt(preset) / 100;
      const amount = balance * percentage;
      setSourceAmount(amount.toString());
    }
  };

  return (
    <div className={(styles.page, styles.pageOverrides)}>
      <div
        style={{
          padding: "8px 20px 0 20px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <span onClick={() => window.history.back()}>
            <ArrowBack />
          </span>
          <h2>Transfer</h2>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "2rem",
            flexDirection: "column",
          }}
        >
          <div>
            <input
              className={styles.swapInput}
              value={sourceAmount}
              type="text"
              inputMode="decimal"
              readOnly // Disable typing from native keyboard
              placeholder="0"
              onChange={(e) => {
                let value = e.target.value;
                console.log("VALUE: ", value);
                if (value.startsWith("00")) {
                  value = value.slice(value.indexOf("0") + 1);
                } else if (value.startsWith("-")) {
                  value = "0";
                }
                if (isNaN(value)) {
                  value = "";
                }
                setSourceAmount(value);
              }}
              style={{
                border: "none",
                background: "transparent",
                fontSize: "3rem",
                color: "white",
                width: `${sourceAmount?.length + 1}ch`,
                maxWidth: "180px",
                minWidth: "2ch",
                // Dynamic width based on content
                textAlign: "right",
              }}
            />
            <span
              className={styles.swapInputSymbol}
              style={{
                fontSize: "3rem",
                color: "gray",
                marginLeft: "10px",
                whiteSpace: "nowrap",
                width: "60%",
              }}
            >
              {fromToken?.symbol}
            </span>
          </div>

          <div className={styles.swapBalanceText}>
            <small>
              Balance:&nbsp;
              {fromToken &&
              sourceNetworkBalances[fromToken.address.toLowerCase()]
                ? parseFloat(
                    sourceNetworkBalances[fromToken.address.toLowerCase()]
                      ?.total
                  )
                : 0}
            </small>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            position: "absolute",
            bottom: "12vh",
            padding: "20px",
            left: "0px",
          }}
        >
          {fromToken && (
            <SwapTokenSelect
              amount={sourceAmount}
              type={"Transfer"}
              selectedToken={fromToken}
              setSelectedToken={setFromToken}
              tokenList={destinationTokenList}
              balances={sourceNetworkBalances}
              networkList={networkList}
              selectedNetwork={destinationNetwork}
              setSelectedNetwork={setDestinationNetwork}
              changeAllowed={true}
            />
          )}
          <div
            style={{
              marginBottom: "20px",
              border: "1px solid #575757",
              ...(validToAddress && {
                border: "1px solid var(--yellow-primary)",
              }),
            }}
            className={styles.swapInputWrapper}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                justifyContent: "space-between",
              }}
            >
              <label>To</label>
              {validToAddress && <BadgeCheck size={16} />}
            </div>
            <div style={{ display: "flex" }}>
              <input
                className={`${styles.swapInput}`}
                value={address}
                type="text"
                placeholder="Enter username or wallet address"
                onChange={(e) => {
                  handleAddressChange(e.target.value);
                }}
                style={{ fontSize: 20 }}
              />
            </div>
          </div>

          <Numpad
            tokenSymbol={fromToken?.symbol}
            onNumberClick={handleNumberClick}
            onBackspaceClick={handleBackspaceClick}
            onPresetClick={handlePresetClick}
          />
          <button
            className="btn-primary w-full"
            disabled={!validSend}
            onClick={() => handleSubmit(addressToSend)}
          >
            {loading ? (
              <CircularProgress size={17} color="inherit" />
            ) : validSend ? (
              "Send"
            ) : !validAmount ? (
              "Enter transfer amount"
            ) : !withinBalance ? (
              "Insufficient balance - Deposit more funds"
            ) : !validToAddress ? (
              "Invalid address"
            ) : (
              "Unknown error"
            )}
          </button>
        </div>
      </div>
    </div>
  );
}

export default TransferPage;
