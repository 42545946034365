import axios from "axios";
import { dummyWallet } from "./constants";

const API_KEY = "72a5b4b0-e727-48be-8aa1-5da9d62fe635"; // SOCKET PUBLIC API KEY
const headers = {
  "API-KEY": API_KEY,
  Accept: "application/json",
  "Content-Type": "application/json",
}
// // Makes a GET request to Bungee APIs for quote
async function getQuote(
  fromChainId,
  fromTokenAddress,
  toChainId,
  toTokenAddress,
  fromAmount,
  userAddress,
  recipient,
  uniqueRoutesPerBridge,
  sort,
  singleTxOnly
) {
  const response = await axios.get(
    `https://api.socket.tech/v2/quote?fromChainId=${fromChainId}&fromTokenAddress=${fromTokenAddress}&toChainId=${toChainId}&toTokenAddress=${toTokenAddress}&fromAmount=${fromAmount}&userAddress=${userAddress}&uniqueRoutesPerBridge=${uniqueRoutesPerBridge}&sort=${sort}&singleTxOnly=${singleTxOnly}&recipient=${recipient}`,
    {
      headers: headers,
    }
  );
  return response.data
}

async function getZapQuote(
  fromChainId,
  fromTokenAddress,
  toChainId,
  toTokenAddress,
  fromAmount,
  userAddress,
  recipient,
  uniqueRoutesPerBridge,
  sort,
  singleTxOnly,
  destinationPayload,
  destinationGasLimit
) {
  let url = `https://api.socket.tech/v2/quote?fromChainId=${fromChainId}&fromTokenAddress=${fromTokenAddress}&toChainId=${toChainId}&toTokenAddress=${toTokenAddress}&fromAmount=${fromAmount}&userAddress=${userAddress}&uniqueRoutesPerBridge=${uniqueRoutesPerBridge}&sort=${sort}&singleTxOnly=${singleTxOnly}&recipient=${recipient}`;

  if (destinationGasLimit && destinationPayload) {
    url = `${url}&destinationPayload=${destinationPayload}&destinationGasLimit=${destinationGasLimit}`;
  }

  const response = await axios.get(
    url,
    {
      headers: headers,
    }
  );

  return response.data;
}

// Makes a POST request to Bungee APIs for swap/bridge transaction data
async function getRouteTransactionData(route) {
  const response = await axios.post("https://api.socket.tech/v2/build-tx", { route: route }, {
    headers: headers
  });

  return response.data;
}

async function getTokensForNetwork(chainId) {
  const response = await axios.get(`https://api.socket.tech/v2/token-lists/chain?chainId=${chainId}`, {
    headers: headers
  });

  return response.data;
};

// Bridging Params fetched from users
//  const fromChainId = 137;
//  const toChainId = 56;
//  const fromAssetAddress = "0x2791bca1f2de4661ed88a30c99a7a9449aa84174";
//  const toAssetAddress = "0x55d398326f99059fF775485246999027B3197955";
//  const amount = 100000000; // 100 USDC, USDC is 6 decimals
//  const userAddress = "0x3e8cB4bd04d81498aB4b94a392c334F5328b237b";

// Fetch tokens for Arbitrum (42161) & OP (10)
// const arbTokens = await getTokensForNetwork("42161");
// const opTokens = await getTokensForNetwork("10");

// Main function
export async function getCallData(
  fromChainId,
  fromAssetAddress,
  toChainId,
  toAssetAddress,
  amount,
  userAddress,
  recipient
) {

      try {
        const uniqueRoutesPerBridge = true; // Returns the best route for a given DEX / bridge combination
        const sort = "output"; // "output" | "gas" | "time"
        const singleTxOnly = true;

        // console.log("Inputs: ", fromChainId,
        //   fromAssetAddress,
        //   toChainId,
        //   toAssetAddress,
        //   amount,
        //   userAddress,
        //   recipient);

        // Quote for bridging 100 USDC on Polygon to USDT on BSC
  // For single transaction bridging, mark singleTxOnly flag as true in query params
  const quote = await getQuote(
    fromChainId,
    fromAssetAddress,
        toChainId,
        toAssetAddress,
        amount,
        userAddress ?? dummyWallet,
        recipient,
        uniqueRoutesPerBridge,
          sort,
          singleTxOnly
      );

    if (quote.result.routes.length == 0) {
      return {
        error: 1,
        message: "No route found"
      }
    }

    // // Choosing first route from the returned route results
    const route = quote.result.routes?.[0];

    // // Fetching transaction data for swap/bridge tx
    const apiReturnData = await getRouteTransactionData(route);

    return {
      route,
      apiReturnData
    }
  } catch (error) {
    console.log(error);
    return {
      error: 1,
      errorObj: error
    }
  }
}

export async function getZapCallData(
  fromChainId,
  fromAssetAddress,
  toChainId,
  toAssetAddress,
  amount,
  userAddress,
  recipient,
  destinationPayload,
  destinationGasLimit
) {
  const uniqueRoutesPerBridge = true; // Returns the best route for a given DEX / bridge combination
  const sort = "output"; // "output" | "gas" | "time"
  const singleTxOnly = true;

  // Quote for bridging 100 USDC on Polygon to USDT on BSC
  // For single transaction bridging, mark singleTxOnly flag as true in query params
  const quote = await getZapQuote(
    fromChainId,
    fromAssetAddress,
    toChainId,
    toAssetAddress,
    amount,
    userAddress,
    recipient,
    uniqueRoutesPerBridge,
    sort,
    singleTxOnly,
    destinationPayload,
    destinationGasLimit
  );

  // // Choosing first route from the returned route results

  let apiReturnData = {};
  const route = quote.result.routes[0];


  // // Fetching transaction data for swap/bridge tx
  if (route) {
    apiReturnData = await getRouteTransactionData(route);
  }

  return {
    route,
    apiReturnData
  }
}
// TOKEN LIST FUNCTIONS
export const getFromTokenList = async (network) => {
  const resp = await axios.get(`https://api.socket.tech/v2/token-lists/from-token-list?fromChainId=${network}&isShortList=true`, {
    headers: headers
  });

  return resp.data;
}

export const getToTokenList = async (network1, network2) => {
  const resp = await axios.get(`https://api.socket.tech/v2/token-lists/to-token-list?fromChainId=${network1}&toChainId=${network2}&isShortList=true`, {
    headers: headers
  });

  return resp.data
}

export const getCompleteTokenList = async () => {
  const resp = await axios.get(`https://api.socket.tech/v2/token-lists/all`, {
    headers: headers
  });

  return resp.data;
}

export const getTokenListByChain = async (chainId) => {
  const resp = await axios.get(`https://api.socket.tech/v2/token-lists/chain?chainId=${chainId}`, {
    headers: headers
  });

  return resp.data;
}

export const getBalancesByTokenAndChain = async (walletAddress, tokenAddress, network) => {
  const resp = await axios.get(`https://api.socket.tech/v2/balances/token-balance?tokenAddress=${tokenAddress}&chainId=${network}&userAddress=${walletAddress}`, {
    headers: headers
  });

  return resp.data;
}

export const getAllBalances = async (walletAddress) => {
  const resp = await axios.get(`https://api.socket.tech/v2/balances?userAddress=${walletAddress}`, {
    headers: headers
  });

  return resp.data
}
