import { ethers } from "ethers";
import { USDC_CONTRACT_ADDRESS } from "./constants";
import ERC20ABI from "../abis/ERC20.json";
import axios from "axios";
import { tokens } from "./constants";
import { RPC_URLS } from "../components/EnclaveSDK/EnclaveUtils/rpcUrls";

const provider = new ethers.JsonRpcProvider(process.env.REACT_APP_RPC_URL);
provider.polling = false;
provider.pollingInterval = 10000;

export const getERC20Balance = async (userAddress) => {
  const contract = new ethers.Contract(
    USDC_CONTRACT_ADDRESS,
    ERC20ABI,
    provider
  );
  const balance = await contract.balanceOf(userAddress);
  return ethers.formatUnits(balance, 6); // Assuming the token has 6 decimal places
};

export const getEtherBalance = async (userAddress) => {
  const balance = await provider.getBalance(userAddress);
  return ethers.formatEther(balance);
};

export const getERC20Balance2 = async (userAddress, network) => {
  console.log("NETWORK: ", network);
  const provider = new ethers.JsonRpcProvider(RPC_URLS[network]);
  const contract = new ethers.Contract(
    tokens.USDC[network],
    ERC20ABI,
    provider
  );
  const balance = await contract.balanceOf(userAddress);
  return ethers.formatUnits(balance, 6); // Assuming the token has 6 decimal places
};

export const getWETHBalance2 = async (userAddress, network) => {
  const provider = new ethers.JsonRpcProvider(RPC_URLS[network]);
  const contract = new ethers.Contract(
    tokens.WETH[network],
    ERC20ABI,
    provider
  );
  const balance = await contract.balanceOf(userAddress);
  return ethers.formatUnits(balance, 18); // Assuming the token has 6 decimal places
};

export const getEtherBalance2 = async (userAddress, network) => {
  const provider = new ethers.JsonRpcProvider(RPC_URLS[network]);
  const balance = await provider.getBalance(userAddress);
  return (
    parseFloat(ethers.formatEther(balance)) +
    parseFloat(await getWETHBalance2(userAddress, network))
  );
};

export const processBalances = (balances) => {
  return balances.reduce((acc, balance) => {
    if (!acc[balance.symbol]) {
      acc[balance.symbol] = {
        symbol: balance.symbol,
        name: balance.name,
        decimals: balance.decimals,
        logoURI: balance.logoURI,
        icon: balance.icon,
        networks: {},
        total: 0,
      };
    }
    acc[balance.symbol].total += balance.amount;
    acc[balance.symbol].networks[balance.chainId] = {
      amount: balance.amount,
      address: balance.address,
    };
    return acc;
  }, {});
};

export const processBalances2 = (balances) => {
  return balances.reduce((acc, balance) => {
    if (!acc[balance.address.toLowerCase()]) {
      acc[balance.address.toLowerCase()] = {
        symbol: balance.symbol,
        name: balance.name,
        decimals: balance.decimals,
        logoURI: balance.logoURI,
        icon: balance.icon,
        networks: {},
        total: 0,
      };
    }
    acc[balance.address.toLowerCase()].total += balance.amount;
    acc[balance.address.toLowerCase()].networks[balance.chainId] = {
      amount: balance.amount,
      address: balance.address,
    };
    return acc;
  }, {});
};

export const formatDate = (timestamp) => {
  const date = new Date(timestamp * 1000);
  return date.toLocaleDateString("en-US", {
    day: "numeric",
    month: "short",
    year: "numeric",
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });
};

export const getRep = (number, sigdig) => {
  if (number == null || number == undefined) {
    return "-";
  }

  if (number / 1000000000 >= 1) {
    return `${(number / 1000000000).toFixed(sigdig)}B`;
  } else if (number / 1000000 >= 1) {
    return `${(number / 1000000).toFixed(sigdig)}M`;
  } else if (number / 1000 >= 1) {
    return `${(number / 1000).toFixed(sigdig)}k`;
  } else {
    return number.toFixed(1);
  }
};
