import axios from "axios";
import { dummyWallet } from "./constants";

const BASE_URL = "https://api-beta.pathfinder.routerprotocol.com/api/v2"

export const getRouterQuote = async (
    amount,
    fromTokenAddress, 
    fromTokenChainId, 
    toTokenAddress, 
    toTokenChainId
) => {
    try {
        const response = await axios.get(BASE_URL + '/quote', {
            params: {
                fromTokenAddress,
                toTokenAddress,
                amount,
                fromTokenChainId,
                toTokenChainId,
                partnerId: process.env.REACT_APP_ROUTER_PARTNER_ID
            },
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return response.data;
    } catch (error) {
        console.error('Error fetching router quote:', error);
        throw error;
    }
}

export const getTransaction = async (quoteResponse, senderAddress, receiverAddress, refundAddress) => {
    const response = await axios.post(BASE_URL + '/transaction', {
        ...quoteResponse,
        senderAddress,
        receiverAddress,
        refundAddress
    }, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return response.data;
}

export const getRouterCallData = async (
    amount,
    fromTokenAddress, 
    fromTokenChainId, 
    toTokenAddress, 
    toTokenChainId,
    userAddr
) => {
    try {
        const quoteResponse = await getRouterQuote(amount, fromTokenAddress, fromTokenChainId, toTokenAddress, toTokenChainId); 

        const walletAddr = userAddr ?? dummyWallet;
        const txnResponse = await getTransaction(quoteResponse, walletAddr, walletAddr, walletAddr);

        return txnResponse;
    } catch (error) {
        console.error('Error fetching router quote:', error);
        return {
            error: 1,
            errorObj: error
        }
    }
}