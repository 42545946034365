import styles from "./css/bottomNav.module.css";

import { NAV_OPTIONS } from "../utils/constants";
import { Link, useLocation } from "react-router-dom";
import { useEnclaveApi } from "./EnclaveSDK/context/EnclaveConnectProvider";

export const BottomNav = () => {
  const location = useLocation();
  const { userData } = useEnclaveApi();

  console.log("In bottom nav: ", userData);

  const hapticFeedback = () => {
    if (navigator.vibrate) {
      navigator.vibrate(2);
    }
  };

  return (
    userData && (
      <>
        <div className={styles.bottomNavContainer}>
          <div className={styles.navItemsContainer}>
            {NAV_OPTIONS.map((itm, i) => (
              <Link
                key={i}
                to={itm.pathname || ""}
                className={`${styles.navListItem2} ${
                  location.pathname === itm.pathname ? styles.activeBlue : ""
                }`}
              >
                <div
                  onClick={hapticFeedback}
                  className={
                    location.pathname === itm.pathname
                      ? styles.activeIconWrapper
                      : styles.iconWrapper
                  }
                >
                  {itm.icon}
                  {/* <span>{itm.label}</span> */}
                </div>
              </Link>
            ))}
          </div>
        </div>
      </>
    )
  );
};
