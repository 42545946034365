import { useState, useEffect } from "react";
import styles from "./common.module.css";
import txnStyles from "../EnclaveSDK/css/transaction.module.css";
import { useEnclaveApi } from "../EnclaveSDK/context/EnclaveConnectProvider";
import { networkDetails } from "../EnclaveSDK/EnclaveUtils/constants";
import { ArrowDown, SendHorizontal, UserX } from "lucide-react";
import { networkDetailsByNameKey } from "../../utils/constants";
import { enabledNetworks } from "../EnclaveSDK/EnclaveUtils/constants";
import { BoltSharp, LinkSharp } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import isPWA from "../../utils/pwaUtils";
import { getExplorerUrl } from "../EnclaveSDK/EnclaveUtils/functions";
import activityStyles from "../EnclaveSDK/css/activity.module.css";
import TxnDetailsModal from "../tnxDetailsModal/tnxDetailsModal";
import { formatPrice } from "../../utils/priceUtils";

const TransactionCard = (props) => {
  const { txn, walletAddress, allTokensList } = props;
  // Add this line

  // console.log("TX DET: ", txn, walletAddress);
  const [viewInternal, setViewInternal] = useState(false);
  const [showTxnDetailsModal, setShowTxnDetailsModal] = useState(false);

  const txnSet = txn.request.transactionDetails;
  const network = txn.request.network;
  const totalValueOfInternalTxns = txnSet?.reduce(
    (sum, transact) => sum + parseFloat(transact.value) || 0,
    0
  );
  const chainDetails = networkDetails[network];

  // or
  // console.log({ token: txn.request.token });
  const closeTxnDetailsModal = () => {
    setShowTxnDetailsModal(false);
  };

  console.log(txn.request.label, "SSSS");

  return (
    <div
      key={txn.response.txnHash}
      className={activityStyles.transactionCard}
      onClick={(e) => {
        e.stopPropagation();
        setShowTxnDetailsModal(true);
      }}
    >
      <div className={activityStyles.tokenLogoBox}>
        <>
          <img
            className={activityStyles.tokenLogo}
            src={
              txn.request.label.includes("Swap")
                ? txn.request.label.split(" ")[2] === "USDC"
                  ? allTokensList.find(
                      (token) =>
                        token.symbol === txn.request.label.split(" ")[5]
                    )?.logoURI
                  : allTokensList.find(
                      (token) =>
                        token.symbol === txn.request.label.split(" ")[2]
                    )?.logoURI
                : allTokensList.find(
                    (token) => token.symbol === txn.request.label.split(" ")[2]
                  )?.logoURI
            }
            alt={chainDetails.name}
          />
          {txn.request.label.includes("Swap") ? null : (
            <div className={activityStyles.actionIcon}>
              {txn.request.label.includes("Send") ? (
                <SendHorizontal size={15} color="white" />
              ) : (
                <ArrowDown size={18} color="white" />
              )}
            </div>
          )}
        </>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "4px",
          width: "100%",
        }}
      >
        {txn.request.label.includes("Swap") ? (
          // For Swap transactions
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "4px",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "column", gap: "4px" }}
            >
              <span style={{ fontSize: "1.1rem" }}>
                {
                  txn.request.label.split(" ")[2] === "USDC"
                    ? `Bought ${txn.request.label.split(" ")[5]}` // When swapping from USDC
                    : `Sold ${txn.request.label.split(" ")[2]}` // When swapping to USDC
                }
              </span>
              <span style={{ fontSize: "0.9rem", color: "gray" }}>
                {chainDetails.name}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
                fontSize: "0.9rem",
              }}
            >
              <span style={{ color: "#4dd15b", textAlign: "right" }}>
                {"+"}
                {((value) => {
                  const num = parseFloat(value);
                  return formatPrice(
                    num.toString().split(".")[1]?.length > 6
                      ? num.toFixed(6)
                      : value
                  );
                })(
                  txn.request.label.slice(
                    txn.request.label.indexOf("to") + 3,
                    txn.request.label.lastIndexOf(" ")
                  )
                )}
                {" " +
                  txn.request.label.slice(txn.request.label.lastIndexOf(" "))}
              </span>

              <span style={{ color: "gray", textAlign: "right" }}>
                {"-"}
                {((value) => {
                  const num = parseFloat(value);
                  return num.toString().split(".")[1]?.length > 6
                    ? num.toFixed(6)
                    : value;
                })(
                  txn.request.label.slice(
                    txn.request.label.indexOf("Swap") + 5,
                    txn.request.label.indexOf(" to")
                  )
                )}
              </span>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "4px",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {/* For other transactions (like Send) */}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "4px",
                justifyContent: "space-between",
              }}
            >
              <span style={{ fontSize: "1.1rem" }}>
                {txn.request.label.slice(0, txn.request.label.indexOf(" "))}{" "}
                {txn.request.label.slice(txn.request.label.indexOf("to"))}
              </span>
              <span style={{ fontSize: "0.9rem", color: "gray" }}>
                {chainDetails.name}
              </span>
            </div>
            <span
              style={{ fontSize: "1rem", color: "gray", textAlign: "right" }}
            >
              {`${txn.request.label.split(" ")[1]} ${
                txn.request.label.split(" ")[2]
              }`}
            </span>
          </div>
        )}
      </div>

      {/* <div className={styles.networkTime}>
        <div className={styles.txnCardNetworkContainer}>
          <img
            style={{ marginRight: "6px" }}
            className={txnStyles.new}
            src={chainDetails.image}
          />
          <p>{chainDetails.name}</p>
        </div>
        <div>{new Date(txn.response.timestamp * 1000).toDateString()}</div>
      </div>
      <div className={styles.txnCardHeading}>
        <h3>{txn.request.label}</h3>
        <h3 style={{ color: "gray" }}>
          {totalValueOfInternalTxns / 1e18 < 0.001
            ? "< 0.001"
            : (totalValueOfInternalTxns / 1e18).toFixed(3)}{" "}
          ETH
        </h3>
      </div>
      <div className={styles.txnHash}>
        <LinkSharp style={{ fontSize: "16px", marginRight: "4px" }} />
        <a
          href={`${getExplorerUrl(network)}${txn.response.txnHash}`}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => e.stopPropagation()}
          className={styles.txnHashLink}
        >
          {spliceAddress(txn.response.txnHash)}
        </a>
      </div> */}
      {/* {viewInternal && (
        <>
          {txnSet.map((intxn, index) => (
            <div className={txnStyles.internalContainer} key={index}>
              <div className={txnStyles.internalHeading}>
                {intxn.label} ({index + 1} / {txnSet.length})
              </div>
              <div className={txnStyles.internalHeading}>
                <h2> From </h2>
                <h2> To </h2>
              </div>

              <div className={txnStyles.transaction}>
                <div className={txnStyles.fromContainer}>
                  <div
                    className={`${txnStyles.circle} ${txnStyles.blue}`}
                  ></div>
                  <h2>{spliceAddress(walletAddress)}</h2>
                </div>
                <img src="/extras/transfer.svg" alt="Transfer" />
                <div className={txnStyles.fromContainer}>
                  <div
                    className={`${txnStyles.circle} ${txnStyles.lime}`}
                  ></div>
                  <h2>{spliceAddress(intxn.targetContractAddress)}</h2>
                </div>
              </div>
            </div>
          ))}
        </>
      )} */}

      {
        <TxnDetailsModal
          isOpen={showTxnDetailsModal}
          closeTxnDetailsModal={closeTxnDetailsModal}
          txn={txn}
          onClick={(e) => e.stopPropagation()}
          allTokensList={allTokensList}
          chainDetails={chainDetails}
        />
      }
    </div>
  );
};

export default function ActivityPage() {
  const { userData, activityLoading, transactions, allTokensList } =
    useEnclaveApi();

  return (
    <div
      className={`${styles.page} ${isPWA() ? styles.pageOverrides : ""}`}
      style={{
        maxWidth: "960px",
        margin: "auto",
        padding: "5px 14px 24px 14px",
      }}
    >
      {!userData || !userData.wallet?.scw_address ? (
        <div className={styles.disconnectedAccount}>
          <UserX size={32} />
          <p>Please connect your account to view details</p>
        </div>
      ) : (
        <>
          <h1>Activity</h1>
          {activityLoading ? (
            <CircularProgress color="inherit" />
          ) : transactions.length === 0 ? (
            <div className={styles.noBalances}>
              <BoltSharp style={{ fontSize: "38px" }} />
              <p style={{ marginTop: "10px", fontSize: "1rem" }}>
                Transaction details will appear once you start trading.
              </p>
              <br />
            </div>
          ) : (
            <div className={styles.transactionContainer}>
              {transactions.map((txn, index) => {
                return (
                  <div key={index}>
                    <TransactionCard
                      txn={txn}
                      walletAddress={userData?.wallet?.scw_address}
                      allTokensList={allTokensList}
                    />
                  </div>
                );
              })}
            </div>
          )}
        </>
      )}
    </div>
  );
}

const spliceAddress = (address) => {
  return address.slice(0, 8) + "..." + address.slice(-4);
};
