import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./common.module.css";
import accountStyles from "../css/account.module.css";
import { processBalances } from "../EnclaveSDK/EnclaveUtils/functions";
import { useEnclaveApi } from "../EnclaveSDK/context/EnclaveConnectProvider";
import {
  getAddressExplorer,
  networkDetails,
} from "../EnclaveSDK/EnclaveUtils/constants";
import CircularProgress from "@mui/material/CircularProgress";
import {
  UserX,
  Copy,
  ArrowRight,
  QrCodeIcon,
  SendIcon,
  HandCoins,
  ArrowUp,
  ArrowDown,
} from "lucide-react"; // Change PiggyBank to Download
import {
  DonutLarge,
  SavingsSharp,
  Person,
  TrendingUp,
  Payment,
  Apple,
  Google,
  AttachMoney,
  Download,
  AddCardOutlined,
  Savings,
  AccountBalance,
} from "@mui/icons-material";
import { networkDetailsByNameKey } from "../../utils/constants";
import { enabledNetworks } from "../EnclaveSDK/EnclaveUtils/constants";
import link from "../EnclaveSDK/assets/icons/link.svg";
import QRCode from "react-qr-code";
import isPWA from "../../utils/pwaUtils";
import ethLogo from "../EnclaveSDK/assets/crypto/ETH.png";
import avaxLogo from "../EnclaveSDK/assets/crypto/avalanche.png";

import dashboardStyles from "../EnclaveSDK/css/dashboard.module.css";
import Transfer from "../EnclaveSDK/Transfer";
import { formatPrice } from "../../utils/priceUtils";

import { loadStripeOnramp } from "@stripe/crypto";

const viewOptions = {
  tokens: "tokens",
  yield: "yield",
  account: "account",
  buy: "buy",
};

export default function DashboardPage() {
  const { userData, balances, balancesLoading, portfolioValue, allTokensList } =
    useEnclaveApi();
  const [processedBalances, setProcessedBalances] = useState({});
  const [transfer, setTransfer] = useState(false);
  const [hideSmallBalances, setHideSmallBalances] = useState(true);

  console.log("XYZ: processed balances: ", processedBalances);

  const navigate = useNavigate();

  const handleDepositClick = () => {
    navigate("/account");
  };

  const handleYieldClick = () => {
    navigate("/earn"); // Change this line
  };

  useEffect(() => {
    if (userData?.wallet?.scw_address) {
      const fetchBalances = async () => {
        try {
          const processedBalances = processBalances(
            balances.filter(
              (balance) =>
                enabledNetworks.includes(balance.chainId) && balance.amount > 0
            )
          );
          setProcessedBalances(processedBalances);
        } catch (error) {
          console.error("Error fetching balances:", error);
        }
      };
      fetchBalances();
    }
  }, [userData, balances]);

  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCopied(false);
    }, 1000);

    return () => clearTimeout(timeout);
  }, [copied]);

  return (
    <div
      className={`${styles.page} ${isPWA() ? styles.pageOverrides : ""}`}
      style={{
        maxWidth: "960px",
        margin: "auto",
        padding: "5px 14px 24px 14px",
        overflowY: "scroll",
      }}
    >
      {!userData || !userData.wallet?.scw_address ? (
        <div className={styles.disconnectedAccount}>
          <UserX size={32} />
          <p>Please connect your account to view details</p>
        </div>
      ) : (
        <>
          {/* <div className={styles.dashboardCard1}>
            <span style={{ display: "block", marginBottom: "8px" }}>
              Welcome
            </span>
            <h1>{userData.username}</h1>
          </div> */}
          <div className={styles.portfolioValueContainer}>
            <span style={{ display: "block", marginBottom: "8px" }}>
              Welcome
            </span>
            <h1>{userData.username}</h1>
            <span style={{ display: "block", marginBottom: "8px" }}>
              Portfolio
            </span>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <span style={{ fontSize: "3rem", fontWeight: "bold" }}>
                {portfolioValue.loading ? (
                  <CircularProgress sx={{ color: "black" }} />
                ) : (
                  `$${formatPrice(portfolioValue.value.toFixed(4))}`
                )}
              </span>
            </div>
          </div>

          <div className={dashboardStyles.addMoneyContainer}>
            <div
              className={dashboardStyles.ctaButtonWrapper}
              onClick={() => navigate("/buy")}
            >
              <div className={dashboardStyles.circularIconWrapper}>
                <ArrowDown />
              </div>
              <span>Add Money</span>
            </div>
            <div
              className={dashboardStyles.qrButton}
              onClick={() => navigate("/account")}
            >
              <QrCodeIcon strokeWidth={3} /> {/* Replace with your icon */}
            </div>
            <div
              style={{ flexDirection: "row-reverse" }}
              className={dashboardStyles.ctaButtonWrapper}
              onClick={() => {
                isPWA() ? navigate("/transfer") : setTransfer(true);
              }}
            >
              <div className={dashboardStyles.circularIconWrapper}>
                <ArrowUp />
              </div>
              <span style={{ textAlign: "right" }}>Send Money</span>
            </div>
          </div>

          {/* <div className={styles.ctaContainer}>
            <div
              className={styles.ctaButtonWrapper}
              onClick={() => navigate("/deposit")}
            >
              <div className={styles.leftSection}>
                <DonutLarge />
                Deposit
              </div>
            </div>
            <div
              className={styles.ctaButtonWrapper}
              onClick={() => navigate("/account")}
            >
              <div className={styles.leftSection}>
                <Person />
                Account
              </div>
            </div>
          </div>
          <div className={styles.ctaContainer}>
            <div
              className={styles.ctaButtonWrapper}
              onClick={() => navigate("/buy")}
            >
              <div className={styles.leftSection}>
                <Payment />
                Buy
              </div>
            </div>
          </div> */}

          <div
            style={{
              paddingBottom:
                processedBalances && Object.keys(processedBalances).length > 0
                  ? "100px"
                  : "0px",
            }}
          >
            {balancesLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "200px",
                }}
              >
                <CircularProgress sx={{ color: "white" }} />
              </div>
            ) : processedBalances &&
              Object.keys(processedBalances).length > 0 ? (
              <div style={{ height: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "20px",
                    gap: "10px",
                    justifyContent: "space-between",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <HandCoins size={30} />
                    <h1 style={{ marginBottom: "0px" }}>Assets</h1>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <span
                      className={styles.toggleText}
                      style={{ fontSize: "14px" }}
                    >
                      Hide small balances
                    </span>
                    <div className={styles.toggleSwitch}>
                      <input
                        type="checkbox"
                        id="hideSmallBalances"
                        checked={hideSmallBalances}
                        onChange={(e) => setHideSmallBalances(e.target.checked)}
                      />
                      <label htmlFor="hideSmallBalances"></label>
                    </div>
                  </div>
                </div>
                <div className={styles.balancesTableWrapper}>
                  <table className={styles.balancesTable}>
                    <tbody>
                      {Object.entries(processedBalances || {})
                        .filter(([symbol, token]) => {
                          return !hideSmallBalances || token.totalUsd >= 0.01;
                        })
                        .map(([symbol, token]) => (
                          <BalanceRow
                            key={symbol}
                            symbol={symbol}
                            token={token}
                          />
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <div style={{ marginTop: "115px" }} className={styles.noBalances}>
                <AccountBalance sx={{ fontSize: 24 }} />
                <br />
                <h2>Welcome to Endeavour!</h2>
                <div>
                  Let's get started by depositing some assets to your account.
                  Click the button below to get started.
                </div>
                <br />
                <button onClick={handleDepositClick} className={"btn-primary"}>
                  Deposit Assets
                </button>
              </div>
            )}
          </div>

          {transfer && (
            <Transfer showTransfer={setTransfer} setWalletView={setTransfer} />
          )}
        </>
      )}
    </div>
  );
}

const BalanceRow = ({ symbol, token }) => {
  const logoURI =
    symbol === "ETH"
      ? ethLogo
      : symbol === "AVAX"
      ? avaxLogo
      : token?.logoURI || "";

  const navigate = useNavigate();

  const handleTokenClick = (symbol, index) => {
    sessionStorage.setItem("exploreTokenIndex", index.toString());
    navigate(`/explore/token/${symbol}`);
  };

  return (
    <>
      <tr
        key={symbol}
        className={styles.yieldRow}
        onClick={(event) => handleTokenClick(token.symbol, event)}
      >
        <td width={42}>
          <div className={styles.logoWrapper}>
            <img width={48} height={48} src={logoURI} alt={token.name} />
          </div>
        </td>
        <td>
          <h3 style={{ fontSize: "1.1rem" }}>{token.name}</h3>
          <h5>
            {formatPrice(token.total.toFixed(4))} {symbol}
          </h5>
        </td>
        {/* <td>
          {Object.keys(token.networks).map(
            (networkId) =>
              networkDetails[networkId] && (
                <img
                  key={networkId}
                  src={networkDetails[networkId].image}
                  alt={networkDetails[networkId].name}
                  title={networkDetails[networkId].name}
                  className={styles.networkIcon}
                  width={24}
                  height={24}
                />
              )
          )}
        </td> */}

        <td className={styles.priceInfo}>
          {
            <>
              <span className={styles.tokenPrice}>
                {token.totalUsd < 0.01
                  ? "< $0.01"
                  : `$${formatPrice(token.totalUsd)}`}
              </span>
              {/* {tokenDetails.priceChange24h && (
                <span
                  className={`${styles.delta} ${
                    parseFloat(tokenDetails.priceChange24h) > 0
                      ? styles.gain
                      : parseFloat(tokenDetails.priceChange24h) < 0
                      ? styles.loss
                      : styles.neutral
                  }`}
                >
                  {parseFloat(tokenDetails.priceChange24h) > 0 ? (
                    <>&#x25B2;</>
                  ) : (
                    <>&#x25BC;</>
                  )}
                  $
                  {Math.abs(
                    (tokenDetails.price *
                      token.total *
                      tokenDetails.priceChange24h) /
                      100
                  ).toFixed(2)}
                </span>
              )} */}
            </>
          }
        </td>
      </tr>
      {/* {viewAll &&
        Object.entries(token.networks).map(([id, details]) => {
          return (
            <tr key={id} className={styles.subYieldRow}>
              <td width={42} style={{ textAlign: "center" }}>
                <img
                  src={networkDetails[parseInt(id)].image}
                  alt={"token-img"}
                  width={18}
                />
              </td>
              <td>
                <p>{networkDetails[parseInt(id)].name}</p>
              </td>
              <td>{details.amount.toFixed(6)}</td>
            </tr>
          );
        })}
      {viewAll && (
        <tr key={"123"} className={styles.subYieldRow}>
          <td width={42}></td>
          <td></td>
          <td></td>
        </tr>
      )} */}
    </>
  );
};

const YieldRow = (props) => {
  const [viewAll, setViewAll] = useState(false);

  const navigate = useNavigate();

  const oppDetailEntries = Object.entries(props.yieldDetails.opportunities);
  const oppDetails = Object.values(props.yieldDetails.opportunities);

  const total = oppDetails.reduce((sum, item) => sum + item.underlyingValue, 0);
  const netApy =
    oppDetails.reduce((sum, item) => sum + item.underlyingValue * item.apy, 0) /
    total;

  return (
    <>
      <tr
        key={props.tokenName}
        className={styles.yieldRow}
        onClick={() => setViewAll(!viewAll)}
      >
        <td width={42}>
          <img
            width={40}
            height={40}
            src={props.yieldDetails.tokenDetails.logoURI}
            alt={props.tokenName}
          />
        </td>
        <td>
          <h3>{props.tokenName}</h3>
          <h5>
            {total} {props.tokenName}
          </h5>
        </td>
        <td>
          <h3>{(netApy * 100).toFixed(6)} %</h3>
        </td>
      </tr>
      {viewAll &&
        oppDetailEntries.map(([integrationId, opp]) => {
          return (
            <tr
              key={opp.primaryStakedAssetDetails.token.name}
              className={styles.subYieldRow}
              onClick={() =>
                navigate(
                  `/yield?integrationId=${integrationId}&tokenSymbol=${props.tokenName}`
                )
              }
              style={{ cursor: "pointer" }}
            >
              <td width={42} style={{ textAlign: "center" }}>
                <img
                  src={networkDetailsByNameKey[opp.network].logo}
                  width={18}
                />
              </td>
              <td>
                <p>
                  {opp.primaryStakedAssetDetails.token.name}
                  <br />
                  {parseFloat(opp.primaryStakedAssetDetails.amount) *
                    parseFloat(opp.primaryStakedAssetDetails.pricePerShare)}
                </p>
              </td>
              <td>
                <p>{(opp.apy * 100).toFixed(6)} %</p>
              </td>
            </tr>
          );
        })}
      {viewAll && (
        <tr key={"123"} className={styles.subYieldRow}>
          <td width={42}></td>
          <td></td>
          <td></td>
        </tr>
      )}
    </>
  );
};

export const Network = (props) => {
  return (
    <div
      className={accountStyles.accountNetwork}
      onClick={() =>
        window.open(getAddressExplorer(props.network, props.address))
      }
    >
      <div className={accountStyles.accountNetworkLeft}>
        <img src={props.img} alt="" />
        <div>
          <p>{props.name}</p>
          <h2>
            {props.address.slice(0, 6) + "..." + props.address.slice(-4)}{" "}
            <img src={link} alt="" />
          </h2>
        </div>
      </div>
      <div className={accountStyles.accountNetworkRight}>
        <div className={accountStyles.deploymentBadgeContainerMobile}>
          <span
            className={`${accountStyles.deploymentBadge} ${
              props.deployed
                ? accountStyles.deployed
                : accountStyles.notDeployed
            }`}
          >
            {props.deployed ? "Deployed" : "Not Deployed"}
          </span>
        </div>
      </div>
    </div>
  );
};
