// Modal.js
import React from "react";
import styles from "./tnxDetailsModal.module.css"; // Create a CSS module for styling
import { useEnclaveApi } from "../EnclaveSDK/context/EnclaveConnectProvider";
import { ArrowDown, SendHorizontal } from "lucide-react";
import { getExplorerUrl } from "../EnclaveSDK/EnclaveUtils/functions";
import { LinkSharp } from "@mui/icons-material";
import { formatDate } from "../../utils/functions";

const TxnDetailsModal = ({
  isOpen,
  closeTxnDetailsModal,
  txn,
  allTokensList,
  chainDetails,
}) => {
  if (!isOpen) return null;

  const handleOverlayClick = (e) => {
    e.stopPropagation();
    if (e.target === e.currentTarget) {
      closeTxnDetailsModal();
    }
  };

  const handleContentClick = (e) => {
    e.stopPropagation(); // Prevent clicks inside modal from closing it
  };

  // const formatDate = (timestamp) => {
  //   return new Date(timestamp * 1000).toLocaleString();
  // };

  const spliceAddress = (address) => {
    return address.slice(0, 8) + "..." + address.slice(-4);
  };

  return (
    <div className={styles.modalOverlay} onClick={handleOverlayClick}>
      <div className={styles.modalContent} onClick={handleContentClick}>
        <div className={styles.tokenLogoBox}>
          <>
            <img
              className={styles.tokenLogo}
              src={
                allTokensList.find(
                  (token) => token.symbol === txn.request.label.split(" ")[2]
                )?.logoURI
              }
              alt={chainDetails.name}
            />
            {txn.request.label.includes("Swap") ? (
              <img
                className={styles.networkLogo}
                src={
                  txn.request.label.includes("Swap")
                    ? allTokensList.find(
                        (token) =>
                          token.symbol === txn.request.label.split(" ")[5]
                      )?.logoURI
                    : "/extras/transfer.svg"
                }
                style={{
                  width: txn.request.label.includes("Swap") ? "28px" : "24px",
                  height: txn.request.label.includes("Swap") ? "28px" : "24px",
                }}
                alt={chainDetails.name}
              />
            ) : (
              <div className={styles.actionIcon}>
                {txn.request.label.includes("Send") ? (
                  <SendHorizontal size={15} color="white" />
                ) : (
                  <ArrowDown size={18} color="white" />
                )}
              </div>
            )}
          </>
        </div>

        <h1 style={{ marginBottom: "20px" }}>
          {txn.request.label.includes("Swap")
            ? `Swapped ${txn.request.label.split(" ")[2]} for ${
                txn.request.label.split(" ")[5]
              }`
            : `${txn.request.label.slice(0, txn.request.label.indexOf(" "))}
            ${txn.request.label.slice(txn.request.label.indexOf("to"))}`}
        </h1>
        <div className={styles.detailsContainer}>
          <div className={styles.detailRow}>
            <span className={styles.label}>Date</span>
            <span className={styles.value}>
              {formatDate(txn.response.timestamp)}
            </span>
          </div>

          <div className={styles.detailRow}>
            <span className={styles.label}>Network</span>
            <span className={styles.value}>Optimism</span>
          </div>

          <div className={styles.detailRow}>
            <span className={styles.label}>Transaction Hash</span>
            <span className={styles.value}>
              {spliceAddress(txn.response.txnHash)}
            </span>
          </div>
        </div>
        <p className={styles.detailsHeader}>
          {txn.request.label.includes("Swap")
            ? "Swap Details"
            : "Transfer Details"}
        </p>
        <div className={styles.detailsContainer}>
          <div className={styles.detailRow}>
            <span className={styles.label}>USDC Sent</span>
            <span className={styles.value}>
              {"-"}
              {txn.request.label.slice(
                txn.request.label.indexOf("Swap") + 5,
                txn.request.label.indexOf(" to")
              )}
            </span>
          </div>

          {txn.request.label.includes("Swap") ? (
            <div className={styles.detailRow}>
              <span className={styles.label}>Asset Received</span>
              <span
                className={styles.value}
                style={{ color: "#4dd15b", textAlign: "right" }}
              >
                {"+"}
                {txn.request.label.slice(
                  txn.request.label.indexOf("to") + 3,
                  txn.request.label.lastIndexOf(" ")
                )}
                {" " +
                  txn.request.label.slice(txn.request.label.lastIndexOf(" "))}
              </span>
            </div>
          ) : (
            <div className={styles.detailRow}>
              <span className={styles.label}>Recipient Address</span>
              <span className={styles.value} style={{ textAlign: "right" }}>
                {spliceAddress(
                  txn.request.label.slice(txn.request.label.indexOf("to") + 3)
                )}
              </span>
            </div>
          )}

          <div className={styles.viewButtonContainer}>
            <a
              href={`${getExplorerUrl(txn.request.network)}${
                txn.response.txnHash
              }`}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.viewButton}
            >
              View on Explorer
            </a>
          </div>
        </div>
        <button
          className={styles.closeButton}
          onClick={(e) => {
            e.stopPropagation();
            closeTxnDetailsModal();
          }}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default TxnDetailsModal;
