import { useEffect, useState, useRef } from "react";
import styles from "./css/nameBox.module.css";
import {
  checkUserName,
  createAccount,
  sendError,
  signInAccount,
} from "./EnclaveUtils/functions";
import { setWindowEnclave } from "./EnclaveUtils/enclaveWindowFunctions";
import { CircularProgress } from "@mui/material";
import { useEnclaveConnect } from "./context/EnclaveConnectProvider";
import isPWA from "../../utils/pwaUtils";
import { LogIn } from "lucide-react";
import Logo from "./assets/logos/EndeavourTransparet.png";

export default function NameBox(props) {
  const { setUserName, setWalletAddress, setUserData } = useEnclaveConnect();
  const bg = useRef(null);

  const [warningText, setWarningText] = useState("");
  const [btnText, setBtnText] = useState("Create");
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");

  const [appState, setAppState] = useState("noName");

  const [userExixts, setUserExists] = useState(false);

  useEffect(() => {
    bg.current.addEventListener("click", (e) => {
      if (e.target === bg.current) {
        props.setNameBox(false);
        props.setConnectionState("disconnected");
      }
    });
  }, []);

  const btnClicked = async (e) => {
    e.preventDefault();

    if (appState === "nameEntered") {
      setLoading(true);
      if (userExixts) {
        try {
          const res = await signInAccount(name);
          console.log(res);
          if (res.authenticated) {
            localStorage.setItem("userData", JSON.stringify(res));
            console.log(localStorage.getItem("userData"));
            setUserName(res.username);
            setWalletAddress(res.wallet.scw_address);
            setUserData(res);
            props.setConnectionState("connected");
            props.setNameBox(false);
            setWindowEnclave(res);
          } else {
            setWarningText("Sign in failed");
          }
        } catch (error) {
          console.error("E1: ", error);
          sendError({
            message: "SIGN IN FAIL",
            error: JSON.stringify(error),
          });
        } finally {
          setLoading(false);
        }
      } else {
        try {
          const res = await createAccount(name);
          console.log(res);
          if (res.wallet.scw_address) {
            localStorage.setItem("userData", JSON.stringify(res));
            setUserName(res.username);
            setWalletAddress(res.wallet.scw_address);
            setUserData(res);
            props.setConnectionState("connected");
            props.setNameBox(false);
            setWindowEnclave(res);
          } else {
            setWarningText("Account Creation failed");
          }
        } catch (error) {
          console.error("E2: ", error);
          sendError({
            message: "CREATE ACCOUNT FAIL",
            error: JSON.stringify(error),
          });
        } finally {
          setLoading(false);
        }
      }
    }

    if (appState === "accountCreated" || appState === "loggedIn") {
      setWarningText("Signing in...");
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
        setAppState("loggedIn");
        props.setNameBox(false);
      }, 1000);
    }
  };

  useEffect(() => {
    console.log("Name entered: ", name);
    if (name.length > 0) {
      setAppState("nameEntered");
      setLoading(true);
      setWarningText("Searching for user...");
      (async () => {
        try {
          const res = await checkUserName(name);
          console.log(res.value);
          if (res.value === true) {
            setWarningText("User already exists");
            setBtnText("Sign In");
            setUserExists(true);
          } else {
            setWarningText("New User Detected");
            setBtnText("Create Account");
            setUserExists(false);
          }
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false);
        }
      })();
    } else {
      setAppState("noName");
      setWarningText("");
      setUserExists(false);
    }
  }, [name]);

  return (
    <div ref={bg} className={styles.main}>
      <div
        className={`${styles.popUp} ${isPWA() ? styles.popUpOverrides : ""}`}
      >
        <div className={styles.logoContainer}>
          <img style={{ width: "35px" }} src={Logo} alt="Connect" />
          {/* <LogIn /> */}
        </div>
        <h1>Sign In</h1>
        <input
          onChange={(e) => {
            setName(e.target.value?.toLowerCase());
          }}
          value={name}
          type="text"
          placeholder="Enter your username here...."
        />
        {warningText.length > 0 && <p>{warningText}</p>}
        <button
          onMouseDown={btnClicked}
          className={`${name.length <= 0 && styles.disabled} ${"btn-primary"}`}
        >
          {loading ? (
            <CircularProgress
              size={20}
              color="inherit"
              style={{ color: "#111" }}
            />
          ) : (
            <h1>{btnText.length > 0 && btnText}</h1>
          )}
        </button>
        <div className={styles.poweredByContainer}>
          <span className={styles.poweredBy}>Powered by </span>
          <img
            style={{
              width: "15px",
              marginLeft: "5px",
              marginRight: "5px",
            }}
            src="/logos/enclave.svg"
            alt="Enclave"
          />{" "}
          <span className={styles.enclave}>Enclave</span>
        </div>
      </div>
    </div>
  );
}
