import { useEffect, useState, useRef } from "react";
import styles from "./css/transaction.module.css";
import {
  getMultiGasFees,
  submitMultiTransaction,
  approveAddressERC20_CallData,
} from "./EnclaveUtils/functions";
import { CircularProgress } from "@mui/material";
import {
  enabledNetworks,
  networkDetails,
  tokens,
  usdcPaymasterMap,
  usdtPaymasterMap,
} from "./EnclaveUtils/constants";
import {
  useEnclaveApi,
  useEnclaveConnect,
} from "./context/EnclaveConnectProvider";
import { fetchEthPrice, fetchAvaxPrice } from "./EnclaveUtils/priceData";
import { processBalances } from "../../utils/functions";
import { ethers } from "ethers";
import usdcLogo from "./assets/crypto/USDC.png";
import usdtLogo from "./assets/crypto/USDT.png";
import TransactionTokenDropdown from "./Components/TransactionTokenDropdown";
import { isMember } from "../../utils/gasNFT";
import isPWA from "../../utils/pwaUtils";
import { getUser } from "./EnclaveUtils/functions";
import { getExplorerUrl } from "./EnclaveUtils/functions";
import { debounce } from "lodash";

const spliceAddress = (address) => {
  return address.slice(0, 8) + "..." + address.slice(-4);
};

const gasModes = {
  NATIVE: "NATIVE",
  USDC: "USDC",
  USDT: "USDT",
  USDC_OVERRIDE: "USDC_OVERRIDE",
  USDT_OVERRIDE: "USDT_OVERRIDE",
  ETH_OVERRIDE: "ETH_OVERRIDE",
  INSUFFICIENT_BALANCE: "INSUFFICIENT_BALANCE",
  GASLESS: "GASLESS",
};

const getMode = (
  gasConfigMode,
  hasSufficientETHBalance,
  hasSufficientUSDCBalance,
  hasSufficientUSDTBalance
) => {
  if (gasConfigMode === gasModes.NATIVE) {
    if (hasSufficientETHBalance) {
      return gasModes.NATIVE;
    } else if (hasSufficientUSDCBalance) {
      return gasModes.USDC_OVERRIDE;
    } else if (hasSufficientUSDTBalance) {
      return gasModes.USDT_OVERRIDE;
    } else {
      return gasModes.INSUFFICIENT_BALANCE;
    }
  } else {
    if (hasSufficientUSDCBalance) {
      return gasModes.USDC;
    } else if (hasSufficientETHBalance) {
      return gasModes.ETH_OVERRIDE;
    } else if (hasSufficientUSDTBalance) {
      return gasModes.USDT_OVERRIDE;
    } else {
      return gasModes.INSUFFICIENT_BALANCE;
    }
  }
};

export default function Transaction(props) {
  const bg = useRef(null);
  const { walletAddress, fetchBalances, balances, userData, allTokensList } =
    useEnclaveApi();
  const { setUserData } = useEnclaveConnect();
  const [transactionState, setTransactionState] = useState("pending");
  const [ethPrice, setEthPrice] = useState(0);
  const [gasFeeLoading, setGasFeeLoading] = useState(true);
  const [txnResult, setTxnResult] = useState({});
  const [viewInternal, setViewInternal] = useState(false);
  const [gasFeeError, setGasFeeError] = useState(false);

  const [gasFeeObject, setGasFeeObject] = useState({
    NATIVE: 0,
    USDC: 0,
    USDT: 0,
    // sponsored: false
    sponsored: true, // Permenantly set to true for gasless transactions based on input token fee
  });

  const internalTxns = window.enclave.transactionDetails.internalTxns;
  const chainId = internalTxns[0].chainId;

  const chainDetails = networkDetails[chainId];

  const tokenList =
    chainId === 8453
      ? [
          {
            name: "USDC",
            logo: usdcLogo,
          },
          {
            name: chainDetails.nativeToken,
            logo: chainDetails.tokenLogo,
          },
        ]
      : [
          {
            name: "USDC",
            logo: usdcLogo,
          },
          {
            name: "USDT",
            logo: usdtLogo,
          },
          {
            name: chainDetails.nativeToken,
            logo: chainDetails.tokenLogo,
          },
        ];

  const [selectedToken, setSelectedToken] = useState(
    userData.gasConfig?.mode === "USDC"
      ? tokenList[0]
      : tokenList[tokenList.length - 1]
  );

  const internalTxnsUSDC = [
    {
      index: internalTxns.length,
      label: "USDC Paymaster: Pay for gas",
      calldata: approveAddressERC20_CallData(
        usdcPaymasterMap[chainId],
        tokens.USDC[chainId],
        chainId,
        ethers.MaxUint256
      ),
      contractAddress: tokens.USDC[chainId],
      chainId: chainId,
      status: "PENDING",
      transactionHash: null,
      transactionOject: null,
    },
    ...internalTxns,
  ];

  const internalTxnsUSDT =
    chainId !== 8453
      ? [
          {
            index: internalTxns.length,
            label: "USDT Paymaster: Pay for gas",
            calldata: approveAddressERC20_CallData(
              usdtPaymasterMap[chainId],
              tokens.USDT[chainId],
              chainId,
              ethers.MaxUint256
            ),
            contractAddress: tokens.USDT[chainId],
            chainId: chainId,
            status: "PENDING",
            transactionHash: null,
            transactionOject: null,
          },
          ...internalTxns,
        ]
      : internalTxns;

  const processedBalances = processBalances(
    balances.filter((balance) => enabledNetworks.includes(balance.chainId))
  );
  const usdcGasValue = gasFeeObject.USDC * ethPrice;
  const usdtGasValue = gasFeeObject.USDT * ethPrice;
  const totalValueOfInternalTxns =
    window.enclave.transactionDetails.internalTxns.reduce(
      (sum, txn) => sum + parseFloat(txn.value) || 0,
      0
    );
  const totalEthValue = window.enclave.transactionDetails.internalTxns.reduce(
    (sum, txn) => sum + parseFloat(txn.value || 0),
    0
  );
  const requiredEthBalance = gasFeeObject.NATIVE + totalEthValue / 10 ** 18;
  const hasSufficientETHBalance =
    processedBalances[chainDetails.nativeToken]?.networks[chainId]?.amount >=
    requiredEthBalance;
  const hasSufficientUSDCBalance =
    processedBalances["USDC"]?.networks[chainId]?.amount >= usdcGasValue;
  const hasSufficientUSDTBalance =
    processedBalances["USDT"]?.networks[chainId]?.amount >= usdtGasValue;
  const canExecuteTransaction =
    (selectedToken.name === chainDetails.nativeToken &&
      hasSufficientETHBalance) ||
    (selectedToken.name === "USDC" && hasSufficientUSDCBalance) ||
    (selectedToken.name === "USDT" && hasSufficientUSDTBalance) ||
    gasFeeObject.sponsored;
  const mode = getMode(
    userData.gasConfig?.mode,
    hasSufficientETHBalance,
    hasSufficientUSDCBalance,
    hasSufficientUSDTBalance
  );
  const mode2 =
    mode === gasModes.NATIVE || mode === gasModes.ETH_OVERRIDE
      ? gasModes.NATIVE
      : gasModes.USDC;
  const txnSet =
    selectedToken.name === gasModes.USDC
      ? internalTxnsUSDC
      : selectedToken.name === gasModes.USDT
      ? internalTxnsUSDT
      : internalTxns;
  const txnFees =
    selectedToken.name === gasModes.USDC
      ? usdcGasValue
      : selectedToken.name === gasModes.USDT
      ? usdtGasValue
      : gasFeeObject.NATIVE;

  const notEnoughValue =
    (processedBalances[chainDetails.nativeToken]?.networks[chainId]?.amount ??
      0) <
    totalEthValue / 10 ** 18;

  const calculateGasFees = async () => {
    setGasFeeLoading(true);

    // Calculate gas fee for ETH transactions
    const ethGasFeeResultPromise = getMultiGasFees(
      window.enclave.address,
      internalTxns.map((txn) => ({
        encodedData: txn.calldata,
        targetContractAddress: txn.contractAddress,
        value: txn.value,
      })),
      chainId
    );

    const usdcGasFeeResultPromise = getMultiGasFees(
      window.enclave.address,
      internalTxnsUSDC.map((txn) => ({
        encodedData: txn.calldata,
        targetContractAddress: txn.contractAddress,
        value: txn.value,
      })),
      chainId
    );

    const usdtGasFeeResultPromise = getMultiGasFees(
      window.enclave.address,
      internalTxnsUSDT.map((txn) => ({
        encodedData: txn.calldata,
        targetContractAddress: txn.contractAddress,
        value: txn.value,
      })),
      chainId
    );
    const memberPromise = isMember(userData.wallet?.scw_address);

    let ethGasFeeResult, usdcGasFeeResult, usdtGasFeeResult, memberResult;

    try {
      console.log("A. CALCULATING GAS FEES");
      [ethGasFeeResult, usdcGasFeeResult, usdtGasFeeResult, memberResult] =
        await Promise.all([
          ethGasFeeResultPromise,
          usdcGasFeeResultPromise,
          usdtGasFeeResultPromise,
          memberPromise,
        ]);
      console.log(
        "B. GAS FEES: ",
        ethGasFeeResult,
        usdcGasFeeResult,
        usdtGasFeeResult
      );
    } catch (error) {
      console.log("C. Error fetching gas fees: ", error);
      setGasFeeError(true);
      return;
    }

    setGasFeeObject({
      NATIVE: ethGasFeeResult.error
        ? parseFloat(ethGasFeeResult.value) / 10 ** 18
        : parseFloat(ethGasFeeResult.result) / 10 ** 18,
      USDC: usdcGasFeeResult.error
        ? parseFloat(usdcGasFeeResult.value) / 10 ** 18
        : parseFloat(usdcGasFeeResult.result) / 10 ** 18,
      USDT: usdtGasFeeResult.error
        ? parseFloat(usdtGasFeeResult.value) / 10 ** 18
        : parseFloat(usdtGasFeeResult.result) / 10 ** 18,
      // sponsored: memberResult
      sponsored: true, // Permenantly set to true for gasless transactions based on input token fee
    });

    setGasFeeLoading(false);
  };

  const debouncedCalculateGasFees = useRef(
    debounce(calculateGasFees, 500) // 500ms delay
  ).current;

  useEffect(() => {
    bg.current.addEventListener("click", (e) => {
      if (e.target === bg.current) {
        props.setTransactionPopUp(false);
      }
    });

    if (chainId === 43114) {
      fetchAvaxPrice().then((res) => {
        setEthPrice(res);
      });
    } else {
      fetchEthPrice().then((res) => {
        setEthPrice(res);
      });
    }
  }, []);

  useEffect(() => {
    if (notEnoughValue) {
      setGasFeeLoading(false);
    } else {
      debouncedCalculateGasFees();
    }

    return () => {
      debouncedCalculateGasFees.cancel(); // Cancel any pending debounced calls on cleanup
    };
  }, []);

  const executeTransaction = () => {
    setTransactionState("processing");
    submitMultiTransaction(
      props.userData.username,
      txnSet.map((txn) => ({
        encodedData: txn.calldata,
        targetContractAddress: txn.contractAddress,
        value: txn.value,
        label: txn.label,
      })),
      chainId,
      gasModes.GASLESS,
      // selectedToken.name === gasModes.USDC ? gasModes.USDC :
      // selectedToken.name === gasModes.USDT ? gasModes.USDT : gasModes.NATIVE,
      window.enclave.transactionDetails.label,
      window.enclave.transactionDetails.feeToken,
      window.enclave.transactionDetails.feeTokenAmount
    ).then((res) => {
      console.log("TXN: ", res);
      setTxnResult(res);

      const updateBalances = () => {
        fetchBalances().then((res) => {
          setTransactionState("completed");
          props.setTransactionPopUp(false);
          getUser(props.userData.username).then((res) => {
            setUserData(res);
          });
        });
      };
      setTimeout(updateBalances, 1000);
      window.dispatchEvent(new Event("completedTransaction", { txnRes: res }));
    });
  };

  console.log("Gas Fee Error: ", gasFeeError);

  return (
    <main ref={bg} className={styles.main}>
      <div
        className={`${styles.popUp} ${isPWA() ? styles.popUpOverrides : ""}`}
      >
        <div className={styles.header}>
          <div className={styles.tokenLogoContainer}>
            <img
              className={styles.tokenLogo}
              src={
                window.enclave.transactionDetails.label.includes("Swap")
                  ? window.enclave.transactionDetails.label.split(" ")[2] ===
                    "USDC"
                    ? allTokensList.find(
                        (token) =>
                          token.symbol ===
                          window.enclave.transactionDetails.label.split(" ")[5]
                      )?.logoURI
                    : allTokensList.find(
                        (token) =>
                          token.symbol ===
                          window.enclave.transactionDetails.label.split(" ")[2]
                      )?.logoURI
                  : allTokensList.find(
                      (token) =>
                        token.symbol ===
                        window.enclave.transactionDetails.label.split(" ")[2]
                    )?.logoURI
              }
              alt={chainDetails.name}
            />
          </div>
          {/* <img className={styles.logo}
                        src="/logos/enclave.svg" alt="Transfer" /> */}
          {/* <div>
            <img
              className={styles.new}
              size={10}
              src={chainDetails.image}
              alt="Transfer"
            />
            <p>{chainDetails.name}</p>
          </div> */}
        </div>
        <br />
        <div className={styles.heading}>
          <h1 style={{ textAlign: "center" }}>
            {window.enclave.transactionDetails.label.includes("Swap")
              ? window.enclave.transactionDetails.label.split(" ")[2] === "USDC"
                ? `Buying ${
                    window.enclave.transactionDetails.label.split(" ")[4]
                  } ${
                    window.enclave.transactionDetails.label.split(" ")[5]
                  } for ${
                    window.enclave.transactionDetails.label.split(" ")[1]
                  }$`
                : `Selling ${
                    window.enclave.transactionDetails.label.split(" ")[1]
                  } ${
                    window.enclave.transactionDetails.label.split(" ")[2]
                  } for ${
                    window.enclave.transactionDetails.label.split(" ")[4]
                  }$`
              : window.enclave.transactionDetails.label.includes("Send")
              ? `Sending ${window.enclave.transactionDetails.label
                  .split(" ")
                  .slice(1)
                  .join(" ")}`
              : window.enclave.transactionDetails.label}
          </h1>
        </div>
        <br />

        {/* <div>
                    Transaction Batch ({txnSet.length})
                    <span className={styles.viewInternalToggle} onClick={
                        () => {
                            setViewInternal(!viewInternal);
                        }
                    }>
                        {viewInternal ? "Hide" : "View"}
                    </span>
                </div> */}
        {viewInternal && (
          <>
            {txnSet.map((txn, index) => (
              <div className={styles.internalContainer} key={index}>
                <div className={styles.internalHeading}>
                  {txn.label} ({index + 1} / {txnSet.length})
                </div>
                <div className={styles.internalHeading}>
                  <h2> From </h2>
                  <h2> To </h2>
                </div>

                <div className={styles.transaction}>
                  <div className={styles.fromContainer}>
                    <div className={`${styles.circle} ${styles.blue}`}></div>
                    <h2>{spliceAddress(walletAddress)}</h2>
                  </div>

                  <img src="/extras/transfer.svg" alt="Transfer" />
                  <div className={styles.fromContainer}>
                    <div className={`${styles.circle} ${styles.lime}`}></div>
                    <h2>{spliceAddress(txn.contractAddress)}</h2>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}

        {/* <div className={styles.heading}>
                    <h1>Transaction Details</h1>
                    <h1> </h1>
                </div>

                <div className={styles.details}>
                    <h2>Value</h2>
                    <h2>
                        {totalValueOfInternalTxns / 1e18} {chainDetails.nativeToken}
                    </h2>
                </div> */}

        {/* {
                    gasFeeObject.sponsored &&
                    <div className={styles.details}>
                        <h3 style={{color: "green", backgroundColor: "lightgreen", padding: "4px", borderRadius: "4px"}}>Sponsored</h3>
                    </div>
                } */}

        <div className={styles.details}>
          {gasFeeError && (
            <h4 className={styles.gasError}>Transaction will fail</h4>
          )}
        </div>
        {((transactionState === "pending" &&
          !gasFeeLoading &&
          !canExecuteTransaction) ||
          notEnoughValue) && (
          <div className={styles.btnCon}>
            <h4>Insufficient Balance</h4>
          </div>
        )}
        {transactionState === "pending" &&
          !gasFeeLoading &&
          canExecuteTransaction &&
          !notEnoughValue &&
          !gasFeeError && (
            <div className={styles.btnCon2}>
              {/* <button className={styles.button}
                            onClick={() => {
                                props.setTransactionPopUp(false)
                            }}>
                            <h1>Cancel</h1>
                        </button> */}
              <button className={styles.button2} onClick={executeTransaction}>
                <h1>Confirm</h1>
              </button>
            </div>
          )}
        {(transactionState === "processing" || gasFeeLoading) &&
          transactionState !== "completed" && (
            <div className={styles.btnCon}>
              <button className={styles.button3} disabled={true}>
                <CircularProgress
                  size={20}
                  color="inherit"
                  style={{ color: "#111" }}
                />
              </button>
            </div>
          )}
        {transactionState === "completed" && txnResult.txnHash && (
          <div className={styles.btnCon}>
            <button
              onClick={() => {
                window.open(
                  `${getExplorerUrl(
                    window.enclave.transactionDetails.internalTxns[0].chainId
                  )}${txnResult.txnHash}`
                );
              }}
              className={styles.button3}
            >
              <h1>View on Explorer</h1>
              <img
                className={styles.arrow}
                src="/icons/arrow.svg"
                alt="Arrow"
              />
            </button>
          </div>
        )}
        {transactionState === "completed" && txnResult.error && (
          <div>
            <h4>Error: {txnResult.error}</h4>
          </div>
        )}
      </div>
    </main>
  );
}
